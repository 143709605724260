<template>
  <div class="home" v-if="!isProcessing">
    <div class="home__menu" v-for="item in items" :key="item.title">
      <home-menu :item="item" />
    </div>
  </div>
</template>

<script>
import HomeMenu from '@/components/common/menu.vue'

export default {
  name: 'home',
  components: { HomeMenu },
  data () {
    return {
      items: [
        { title: '動画', content: '動画情報に関する操作ができます', to: 'movie' },
        { title: '企画', content: '企画情報に関する操作ができます', to: 'project' },
        { title: 'イベント', content: 'イベント情報に関する操作ができます', to: 'event' },
        { title: '出演者', content: '出演者情報に関する操作ができます', to: 'guest' },
        { title: 'ログアウト', content: 'ログアウトできます', func: () => this.signout() }
      ]
    }
  },
  mounted () {
    // 本番環境の場合はリセット関数を非表示にする
    if (this.isProduction) {
      this.items = this.items.filter(item => item.to !== 'reset')
    }
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 本番環境かどうか
     */
    isProduction () {
      return process.env.NODE_ENV === 'production'
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * サインアウト
     */
    async signout () {
      this.$store.commit('setSubmitting', true)
      await this.$store.dispatch('auth/signout')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.home {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: 0 $padding_width;
  margin: $header_height auto 0 auto;
  &__menu {
    width: 100vw;
    max-width: $max_width;
    margin-left: -$padding_width;
  }
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'

import store from '@/store'
import { firebase } from '@/firebase'

/**
 * vue-router v3.1.0から、同一画面に遷移するとコンソールエラーが発生するようになった
 * push関数にエラーハンドリングを追加して、デフォルトの関数を上書きすることで対応
 * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/guest',
      name: 'guest',
      component: loadComponent('Guest.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/guest/list',
      name: 'guest_list',
      component: loadComponent('GuestList.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/guest/add',
      name: 'guest_add',
      component: loadComponent('GuestAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/guest/edit/:uid',
      name: 'guest_edit',
      component: loadComponent('GuestEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/movie',
      name: 'movie',
      component: loadComponent('Movie.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/movie/list',
      name: 'movie_list',
      component: loadComponent('MovieList.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/movie/edit/:mvid',
      name: 'movie_edit',
      component: loadComponent('MovieEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/movie/add',
      name: 'movie_add',
      component: loadComponent('MovieAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/movie/add/recommend',
      name: 'movie_add_recommend',
      component: loadComponent('MovieAddRecommend.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/project',
      name: 'project',
      component: loadComponent('Project.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/project/list',
      name: 'project_list',
      component: loadComponent('ProjectList.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/project/edit/:prid',
      name: 'project_edit',
      component: loadComponent('ProjectEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/project/add',
      name: 'project_add',
      component: loadComponent('ProjectAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/project/add/recommend',
      name: 'project_add_recommend',
      component: loadComponent('ProjectAddRecommend.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/event',
      name: 'event',
      component: loadComponent('Event.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/event/edit/:evid',
      name: 'event_edit',
      component: loadComponent('EventEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/event/list',
      name: 'event_edit_list',
      component: loadComponent('EventEditList.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/event/add',
      name: 'event_add',
      component: loadComponent('EventAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: loadComponent('Login.vue')
    },
    {
      path: '/error',
      name: 'error',
      component: loadComponent('Error.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: loadComponent('NotFound.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '**',
      redirect: { name: 'notfound' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {Object} 遅延ロードしたコンポーネント
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // 認証後の遷移先として現在のURLを保存する
  const redirectURL = store.getters.redirectPath
  if (!redirectURL) store.commit('setRedirectURL', to.path)

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const uid = auth ? auth.uid : null
  const isAuthProcessing = store.getters.isAuthProcessing

  // 認証、ユーザー取得状況に応じてページ遷移
  // 初回アクセス時、onAuthの処理が終わる前にrouterが動くため、初回処理が終わるまで遷移をループさせる
  if (isAuthProcessing || autoTransition) {
    // Auth処理中の場合は遷移をキャンセルさせる
    autoTransition ? next() : next(false)
  } else if (!uid) {
    // 未ログインの場合
    requiresAuth ? next({ name: 'login' }) : next()
  } else {
    // ログイン済みの場合
    requiresAuth ? next() : next({ name: 'home' })
  }
})

/**
 * beforeEachで遷移が確定した後の処理
 */
router.beforeResolve((to, from, next) => {
  const isAuthProcessing = store.getters.isAuthProcessing

  // 遷移先と遷移元が異なればisProcessingをtrueにする
  if (!isAuthProcessing && to.name !== from.name) store.commit('setProcessing', true)
  next()
})

export default router

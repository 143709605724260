import { firebase, provider } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    uid: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} ユーザーID
   */
  uid: state => state.uid
}

const mutations = {
  /**
    * ユーザーIDをstateにセット
    * @param {Object} state 暗黙的に受け取るstate
    * @param {String} uid ユーザーID
    */
  setUID: (state, uid) => {
    state.uid = uid
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
    * ユーザ情報が更新時、uidを更新する
    */
  onAuth ({ commit, getters, rootGetters, dispatch }) {
    firebase.auth().onAuthStateChanged(async auth => {
      commit('setAuthProcessing', true, { root: true })

      // auth情報をセット
      commit('setUID', auth ? auth.uid : null)

      if (auth) {
        // ユーザー情報の取得
        const user = await dispatch('users/getUser', auth.uid, { root: true })

        // 権限の確認
        // ユーザーが存在しない or 権限がない場合はログアウトして警告
        if (!user || user.authority !== 'admin') {
          await dispatch('signout')
          commit('setTelop', { show: true, msg: 'アクセス権限がありません', type: 'error' }, { root: true })
          commit('setRedirectURL', '/login', { root: true })
        }
      }

      commit('setAuthProcessing', false, { root: true })

      // 初回アクセスの場合にリダイレクトさせ、routerを反応させる
      const redirectPath = rootGetters.redirectPath
      router.replace({ path: redirectPath, query: { auth: new Date().getTime() } })
    })
  },
  /**
   * サインイン
   * @param {String} sns SNS認証のプロバイダー名 google, facebook, twitter
   */
  signin: async ({ commit }, sns) => {
    try {
      await firebase.auth().signInWithRedirect(provider[sns])
    } catch {
      // エラーの場合はエラー画面に遷移させる
      router.push({ name: 'error' })
    }
  },
  /**
   * サインアウト
   */
  signout: async ({ commit, dispatch }) => {
    try {
      await firebase.auth().signOut()

      // storeの不要な情報をリセットする
      dispatch('resetState', null, { root: true })
    } catch {
      // エラーの場合はエラー画面に遷移させる
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

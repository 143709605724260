import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // おすすめ情報
    recommends: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} おすすめ情報
   */
  recommends: state => state.recommends
}

const mutations = {
  /**
   * おすすめ情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} recommends おすすめ情報
   */
  setRecommends: (state, recommends) => {
    state.recommends = Object.assign(state.recommends, recommends)
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * おすすめ情報を取得
   */
  getRecommends: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('recommends')
        .get()

      snapshot.docs.forEach(doc => {
        const recommends = Object.assign({ rid: doc.id }, doc.data())
        commit('setRecommends', recommends)
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * おすすめ情報の追加
   * @param {Object} params 追加するすすめ情報
   */
  addRecommends: async ({ commit }, params) => {
    try {
      await firestore
        .collection('recommends')
        .add(params)

      // 一度リセットして再取得させる
      commit('resetState')
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * おすすめ情報の更新
   * @param {String} rid おすすめ情報のドキュメントID
   * @param {Object} params 更新するすすめ情報
   */
  updateRecommends: async ({ commit }, { rid, params }) => {
    try {
      await firestore
        .collection('recommends')
        .doc(rid)
        .update(params)

      // 一度リセットして再取得させる
      commit('resetState')
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

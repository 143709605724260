import { firestore, convertDateToTimestamp } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 全動画を取得したかどうか
    isGotAllMovie: false,
    // 動画の一覧
    // { mvid: Object, mvid: Object, ... }
    movies: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 全動画を取得したかどうか
   */
  isGotAllMovie: state => state.isGotAllMovie,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 動画の一覧
   */
  movies: state => state.movies,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} mvid 動画ID
   * @return {Object} 動画の一覧
   */
  movie: state => mvid => state.movies[mvid] || null
}

const mutations = {
  /**
   * 全動画を取得したかどうかをstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  setIsGotAllMovie: state => {
    state.isGotAllMovie = true
  },
  /**
   * 動画情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid 動画のドキュメントID
   * @param {Object} movie 動画情報
   */
  setMovie: (state, { mvid, movie }) => {
    state.movies = Object.assign({}, state.movies, { [mvid]: movie })
  },
  /**
   * stateの動画情報を更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} mvid 動画のドキュメントID
   * @param {Object} params 更新された動画情報
   */
  updateMovie: (state, { mvid, params }) => {
    // 更新前の値
    const movie = state.movies[mvid]
    // 新しい値に更新
    Object.keys(params).forEach(key => movie[key] = params[key])

    // stateの更新
    state.movies = Object.assign({}, state.movies, { [mvid]: movie })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定した動画IDの動画情報を取得
   * @param {String} prid 企画ID
   * @param {String} mvid 動画ID
   */
  getMovie: async ({ commit }, { prid, mvid }) => {
    try {
      const doc = await firestore
        .collection('projects')
        .doc(prid)
        .collection('movies')
        .doc(mvid)
        .get()

      // もし存在しない場合は処理を終了
      if (!doc.exists) return

      const movie = Object.assign(doc.data(), { mvid: mvid, prid: prid })
      commit('setMovie', { mvid: mvid, movie: movie })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 全動画情報を取得
   */
  getMovies: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collectionGroup('movies')
        .orderBy('createdAt', 'desc')
        .get()

      snapshot.docs.forEach(doc => {
        const prid = doc.ref.parent.parent.id
        const movie = Object.assign(doc.data(), { mvid: doc.id, prid: prid })
        commit('setMovie', { mvid: doc.id, movie: movie })
      })

      commit('setIsGotAllMovie')
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定されたユーザーが出演している動画情報を取得
   *
   * @param {String} uid ユーザーID
   * @return {Object[]} 動画情報
   */
  getMoviesByUID: async ({}, uid) => {
    try {
      const snapshot = await firestore
        .collectionGroup('movies')
        .where('guests', 'array-contains', uid)
        .get()

      const docs = snapshot.docs.map(doc => Object.assign({ mvid: doc.id }, doc.data()))
      return docs
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 動画情報の追加
   * @param {String} prid プロジェクトID
   * @param {Object} params 追加する情報
   */
  addMovie: async ({ commit }, { prid, params }) => {
    try {
      const ref = await firestore
        .collection('projects')
        .doc(prid)
        .collection('movies')
        .add(params)

      // 日付情報をTimestampに変更
      params.createdAt = convertDateToTimestamp(params.createdAt)
      params.updatedAt = convertDateToTimestamp(params.updatedAt)

      const movie = Object.assign(params, { mvid: ref.id, prid: prid })
      commit('setMovie', { mvid: ref.id, movie: movie })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 動画情報の更新
   * @param {String} prid プロジェクトID
   * @param {String} mvid 更新する動画ID
   * @param {Object} params 更新する情報
   */
  updateMovie: async ({ commit }, { prid, mvid, params }) => {
    try {
      await firestore.collection('projects').doc(prid).collection('movies').doc(mvid).update(params)
      commit('updateMovie', { mvid: mvid, params: params })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

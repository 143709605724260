/**
 * Vimeo APIを利用して値の取得等を行っている
 * @see https://developer.vimeo.com/api/oembed/videos
 */
import axios from 'axios'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 全vimeoの情報一覧
    // { url: {}, url: {}, ... }
    vimeos: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} vimeoの情報
   */
  vimeos: state => state.vimeos,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} url vimeoのurl
   * @return {Object} vimeoの情報
   */
  vimeo: state => url => state.vimeos[url] ? state.vimeos[url] : null
}

const mutations = {
  /**
   *
   * @param {*} state
   */
  setVimeo: (state, { url, vimeo }) => {
    state.vimeos[url] = vimeo
  },
  /**
   * stateのリセットを行う
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * vimeoの情報を取得
   * @param {String} url vimeoのURL
   */
  getVimeo: async ({ commit }, url) => {
    try {
      // パラメータ
      const params = new URLSearchParams()
      params.append('url', url)

      const response = await axios.get('https://vimeo.com/api/oembed.json?' + params)
      commit('setVimeo', { url: url, vimeo: response.data })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}



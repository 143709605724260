import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import users from '@/store/modules/users'
import guests from '@/store/modules/guests'
import events from '@/store/modules/events'
import projects from '@/store/modules/projects'
import movies from '@/store/modules/projects/movies'
import recommends from '@/store/modules/recommends'
import functions from '@/store/modules/functions'
import cloudstorage from '@/store/modules/cloudstorage'
import vimeos from '@/store/modules/vimeos'

Vue.use(Vuex)

const modules = { auth, users, guests, events, projects, movies, recommends, functions, cloudstorage, vimeos }

export default new Vuex.Store({
  modules: modules,
  state: {
    isProcessing: true,
    isSubmitting: false,
    isAuthProcessing: true,
    redirectPath: null,
    telop: {
      show: false,
      msg: '',
      type: ''
    },
    emails: {},
    MAX_LENGTH: {
      USER: {
        name: 20,
        position: 20,
        profile: 260
      },
      PROJECT: {
        name: 40,
        description: 300
      },
      EVENT: {
        name: 60,
        description: 300,
        organizer: 30
      }
    },
    EXCLUDE_KEYS: {
      EVENT: {
        footballcoachMemberOnline: ['externalURL'],
        organizeOther: ['guests'],
        online: ['location'],
        offline: ['distributionURL']
      }
    },
    /**
     * 入力項目に対するバリデーション定義
     * - valid: 有効な値
     * - requiredBlank: 必須項目が未入力
     * - overLength: 規定された文字数を超えている
     * - typeInvalid: 書式が無効 (例: link)
     */
    VALIDATION_DEFINITION: {
      valid: 'valid',
      requiredBlank: 'requiredBlank',
      overLength: 'overLength',
      typeInvalid: 'typeInvalid'
    }
  },
  getters: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 処理中かどうか
     */
    isProcessing: state => state.isProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} フォーム等の送信中かどうか
     */
    isSubmitting: state => state.isSubmitting,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} Auth処理中かどうか
     */
    isAuthProcessing: state => state.isAuthProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURL
     */
    redirectPath: state => state.redirectPath,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} テロップの情報
     */
    telop: state => state.telop,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} ユーザーのメールアドレス一覧 { uid: メールアドレス, uid: メールアドレス, ... }
     */
    emails: state => state.emails,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Objcet} プロフィールの格納できる文字数の最大値
     */
    MAX_LENGTH: state => state.MAX_LENGTH,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Objcet} 入力内容に応じて入力が不要な項目（キー名）が格納されたオブジェクト (例：オンラインイベントでは住所の入力は不要)
     */
    EXCLUDE_KEYS: state => state.EXCLUDE_KEYS,
    /**
     * @param {Objcet} state 暗黙的に受け取るstate
     * @return {Object} バリデーション結果の定義
     */
    VALIDATION_DEFINITION: state => state.VALIDATION_DEFINITION
  },
  mutations: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isAuthProcessing Auth処理中かどうか
     */
    setAuthProcessing: (state, isAuthProcessing) => {
      state.isAuthProcessing = isAuthProcessing
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isProcessing 処理中かどうか
     */
    setProcessing: (state, isProcessing) => {
      state.isProcessing = isProcessing
      if (state.isSubmitting) state.isSubmitting = false
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isSubmitting フォーム等の送信中かどうか
     */
    setSubmitting: (state, isSubmitting) => {
      state.isSubmitting = isSubmitting
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} path アクセスされたURL
     */
    setRedirectURL: (state, path) => {
      state.redirectPath = path
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} telop テロップの情報
     * @param {Boolean} telop.show テロップ表示の有無
     * @param {String} telop.msg テロップの内容
     * @param {String} telop.type テロップの色 success, warning, error
     */
    setTelop: (state, telop) => {
      state.telop = telop
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} emails メールアドレス一覧
     */
    setEmails: (state, emails) => {
      state.emails = emails
    }
  },
  actions: {
    /**
     * 全モジュールのstoreをリセットする
     */
    resetState: ({ commit }) => {
      Object.keys(modules).forEach(key => {
        if (Object.keys(modules[key].state).length !== 0) commit(key + '/resetState')
      })
    }
  }
})

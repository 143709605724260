<template>
  <header class="header" v-if="isDisplay">
    <v-btn text class="header__btn" v-if="type.includes('back')" @click="back()">
      <v-icon class="header__btn__icon">arrow_back</v-icon>
    </v-btn>
    <v-img class="header__logo" v-if="type.includes('logo')" :src="require('@/assets/img/logo.png')" contain />
    <p class="header__title" v-if="type.includes('title')">{{ title }}</p>
  </header>
</template>

<script>
// ヘッダーを表示させないページ名
const NOT_DISPLAY = [
  'login',
  'error',
  'notfound'
]

// ヘッダーの詳細
// type: back, logo, title
const HEADER_DETAIL = {
  home: {
    type: ['title'],
    title: '管理者画面'
  },
  guest: {
    type: ['back', 'title'],
    title: '出演者'
  },
  guest_list: {
    type: ['back', 'title'],
    title: '出演者の編集・削除'
  },
  guest_add: {
    type: ['back', 'title'],
    title: '出演者の登録'
  },
  guest_edit: {
    type: ['back', 'title'],
    title: '出演者の編集'
  },
  movie: {
    type: ['back', 'title'],
    title: '動画'
  },
  movie_list: {
    type: ['back', 'title'],
    title: '動画の編集'
  },
  movie_edit: {
    type: ['back', 'title'],
    title: '動画の編集'
  },
  movie_add: {
    type: ['back', 'title'],
    title: '動画の登録'
  },
  movie_add_recommend: {
    type: ['back', 'title'],
    title: 'おすすめ動画の登録'
  },
  project: {
    type: ['back', 'title'],
    title: 'プロジェクト'
  },
  project_list: {
    type: ['back', 'title'],
    title: 'プロジェクトの編集'
  },
  project_edit: {
    type: ['back', 'title'],
    title: 'プロジェクトの編集'
  },
  project_add: {
    type: ['back', 'title'],
    title: 'プロジェクトの登録'
  },
  project_add_recommend: {
    type: ['back', 'title'],
    title: 'おすすめ企画の登録'
  },
  event: {
    type: ['back', 'title'],
    title: 'イベント'
  },
  event_edit: {
    type: ['back', 'title'],
    title: 'イベントの編集'
  },
  event_edit_list: {
    type: ['back', 'title'],
    title: 'イベントの編集'
  },
  event_add: {
    type: ['back', 'title'],
    title: 'イベントの登録'
  }
}

export default {
  computed: {
    /**
     * @return {Boolean} ヘッダーを表示させるかどうか
     */
    isDisplay () {
      return !NOT_DISPLAY.includes(this.$route.name)
    },
    /**
     * @return {String} router.jsで定義された名前
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {String} ヘッダーのタイトル
     */
    title () {
      return this.pageName && HEADER_DETAIL[this.pageName].title ? HEADER_DETAIL[this.pageName].title : ''
    },
    /**
     * @return {String[]} ヘッダーのタイプ
     */
    type () {
      return this.pageName && HEADER_DETAIL[this.pageName].type ? HEADER_DETAIL[this.pageName].type : []
    }
  },
  methods: {
    /**
     * 前のページに戻る
     * 直リンクなどで前のページが存在しない場合はホームへ遷移させる
     */
    back () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.header {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: $header_zindex;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  max-width: $max_width;
  height: $header_height;
  text-align: center;
  background-color: $white_normal_color;
  transform: translate(-50%, 0);
  &__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: $padding_width;
    margin: auto;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: 20px;
      padding: 0;
      color: $black_lighten_color;
    }
    &__icon {
      width: 20px;
      height: 20px;
      &.v-icon {
        font-size: 2rem;
      }
    }
  }
  &__logo {
    width: 120px;
    max-height: calc(#{$header_height} - 10px);
    margin: 0 auto;
  }
  &__title {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}
</style>

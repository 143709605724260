import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 全プロジェクトを取得したかどうか
    isGotAllProject: false,
    // プロジェクトの一覧
    // { prid: Object, prid: Object, ... }
    projects: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 全プロジェクトを取得したかどうか
   */
  isGotAllProject: state => state.isGotAllProject,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} プロジェクトの一覧
   */
  projects: state => state.projects,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid プロジェクトId
   * @return {Object} プロジェクト情報
   */
  project: state => prid => state.projects[prid] || null
}

const mutations = {
  /**
   * 全プロジェクトを取得したかどうかをstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  setIsGotAllProject: state => {
    state.isGotAllProject = true
  },
  /**
   * プロジェクト情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid プロジェクトのドキュメントID
   * @param {Object} project プロジェクト情報
   */
  setProject: (state, { prid, project }) => {
    state.projects = Object.assign({}, state.projects, { [prid]: project })
  },
  /**
   * stateのプロジェクト情報を更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid プロジェクトのドキュメントID
   * @param {Object} params 更新されたプロジェクト情報
   */
  updateProject: (state, { prid, params }) => {
    // 更新前の値
    const project = state.projects[prid]
    // 新しい値に更新
    Object.keys(params).forEach(key => project[key] = params[key])

    // stateの更新
    state.projects = Object.assign({}, state.projects, { [prid]: project })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * プロジェクト情報を取得
   * @param {String} prid 取得するプロジェクトID
   */
  getProject: async ({ commit }, prid) => {
    try {
      const doc = await firestore
        .collection('projects')
        .doc(prid)
        .get()

      if (doc.exists) commit('setProject', { prid: doc.id, project: Object.assign(doc.data(), { prid: doc.id }) })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 全プロジェクト情報を取得
   */
  getProjects: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('projects')
        .get()

      snapshot.docs.forEach(doc => {
        commit('setProject', { prid: doc.id, project: Object.assign(doc.data(), { prid: doc.id }) })
      })

      commit('setIsGotAllProject')
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定されたユーザーが出演している企画情報を取得
   *
   * @param {String} uid ユーザーID
   * @return {Object[]} 企画情報
   */
  getProjectsByUID: async ({}, uid) => {
    try {
      const snapshot = await firestore
        .collection('projects')
        .where('guests', 'array-contains', uid)
        .get()

      const docs = snapshot.docs.map(doc => Object.assign({ eid: doc.id }, doc.data()))
      return docs
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * プロジェクト情報の追加
   * @param {Object} params 追加する情報
   */
  addProject: async ({ commit }, params) => {
    try {
      const ref = await firestore
        .collection('projects')
        .add(params)

      commit('setProject', { prid: ref.id, project: Object.assign(params, { prid: ref.id }) })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * プロジェクト情報の更新
   * @param {String} prid 更新するプロジェクトID
   * @param {Object} params 更新する情報
   */
  updateProject: async ({ commit }, { prid, params }) => {
    try {
      await firestore.collection('projects').doc(prid).update(params)
      commit('updateProject', { prid: prid, params: params })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

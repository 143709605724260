import { firestore, fieldValue } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // イベントの一覧
    // { evid: { name, description, ... }, evid: { ... }, ... }
    events: {},
    // 表示対象のイベントID一覧(開催日時が近い順)
    displayedEventIDs: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String[]} 表示対象のイベントID一覧(開催日時が近い順)
   */
  displayedEventIDs: state => state.displayedEventIDs,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid イベントID
   * @return {Object} イベント情報
   */
  event: state => evid => state.events[evid] || null
}

const mutations = {
  /**
   * イベント情報を追加する
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} evid イベントID
   * @param {Object} event イベント
   */
  setEvent: (state, { evid, event }) => {
    state.events = Object.assign({}, state.events, { [evid]: event })
  },
  /**
   * 表示対象のイベントID一覧を更新する
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String[]} displayedEventIDs 表示対象のイベントID一覧
   */
  setDisplayedEventIDs: (state, displayedEventIDs) => {
    state.displayedEventIDs = displayedEventIDs
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * IDに紐づくイベント情報を取得する
   * @param {String} evid イベントID
   */
  getEvent: async ({ commit }, evid) => {
    try {
      const doc = await firestore
        .collection('events')
        .doc(evid)
        .get()

      if (doc.exists) {
        commit('setEvent', { evid: doc.id, event: Object.assign(doc.data(), { evid: doc.id }) })
      }
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 開催日時が古い順にイベント情報を取得する
   */
  getEvents: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('events')
        .orderBy('startAt', 'desc')
        .get()

      if (!snapshot.empty) {
        const displayedEventIDs = []
        snapshot.docs.forEach(doc => {
          commit('setEvent', { evid: doc.id, event: doc.data() })
          displayedEventIDs.push(doc.id)
        })
        commit('setDisplayedEventIDs', displayedEventIDs)
      }
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定されたユーザーが出演しているイベント情報を取得
   *
   * @param {String} uid ユーザーID
   * @return {Object[]} イベント情報
   */
  getEventsByUID: async ({}, uid) => {
    try {
      const snapshot = await firestore
        .collection('events')
        .where('guests', 'array-contains', uid)
        .get()

      const docs = snapshot.docs.map(doc => Object.assign({ eid: doc.id }, doc.data()))
      return docs
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * イベント情報を新規追加する
   * @param {Object} event 追加するイベント情報
   */
  addEvent: async ({ commit }, event) => {
    try {
      await firestore
        .collection('events')
        .add(event)

      commit('setDisplayedEventIDs', [])
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * イベント情報の更新
   *
   * @param {String} evid 更新対象のイベントID
   * @param {Object} params 更新するイベント情報
   */
  updateEvent: async ({ commit }, { evid, params }) => {
    try {
      await firestore.collection('events').doc(evid).update(params)

      commit('setDisplayedEventIDs', [])
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * イベント情報の指定された要素を削除する
   *
   * @param {String} evid 更新対象のイベントID
   * @param {String[]} fieldNames 削除対象の要素名
   */
  deleteEventFields: async ({ dispatch }, { evid, fieldNames }) => {
    try {
      const deleteFields = {}
      fieldNames.forEach(name => deleteFields[name] = fieldValue.delete())
      await firestore.collection('events').doc(evid).update(deleteFields)

      await dispatch('getEvent', evid)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

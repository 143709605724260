import { firebase } from '@/firebase'
import router from '@/router'

// functionsがあるリージョン
const REGION = 'asia-northeast1'

const getDefaultState = () => {
  return {
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * メールアドレスからユーザー情報を取得する
   * @param {String} email メールアドレス
   * @return {Object} { email: メールアドレス, uid: ユーザーID, user: ユーザー情報 }
   */
  getUserByEmail: async ({ commit }, email) => {
    try {
      const get = firebase.functions(REGION).httpsCallable('getUserByEmail')
      const results = await get({ email: email })
      if (results.data.user) commit('users/setUser', { uid: results.data.uid, user: results.data.user }, { root: true })
      return results.data
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * firestoreのusersに紐づくメールアドレスを全取得する
   */
  getAllEmails: async ({ commit }) => {
    try {
      const getAllEmails = firebase.functions(REGION).httpsCallable('getAllEmails')
      const result = await getAllEmails()
      commit('setEmails', result.data, { root: true })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

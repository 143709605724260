import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 講師のユーザーID一覧
    uids: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String[]} 講師のユーザーID一覧
   */
  uids: state => state.uids
}

const mutations = {
  /**
   * 講師のuidをstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   */
  setUID: (state, uid) => {
    state.uids.push(uid)
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 全講師情報を取得
   */
  getGuests: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('guests')
        .get()

      snapshot.docs.forEach(doc => {
        commit('setUID', doc.id)
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 講師の追加
   * @param {String} uid ユーザーID
   */
  setGuest: async ({ commit }, uid) => {
    try {
      await firestore
        .collection('guests')
        .doc(uid)
        .set({
          createdAt: new Date()
        })

      // 一度リセットして再取得させる
      commit('resetState')
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 講師の削除
   * @param {String} uid ユーザーID
   */
  deleteGuest: async ({ commit }, uid) => {
    try {
      await firestore
        .collection('guests')
        .doc(uid)
        .delete()

      // 一度リセットして再取得させる
      commit('resetState')
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
